<template>
    <v-container>
        <div class="d-flex align-center flex-wrap mb-12" style="gap: 16px">
            <NavButton />
            <h2 class="text-uppercase d-inline-block">Documentos de la convocatoria</h2>
        </div>
        <v-btn
            v-if="esAdministradorConvocatorias" 
            color="primary"
            @click.stop="modalCreacionDocumentoAbierta = true"
        >
            Crear documento
        </v-btn>
        <DataTableComponent 
            :items="documentos.data"
            :headers="tableHeaders"
            :show_loading="documentos.isLoading"
            :total_registros="total"
            @paginar="manejarPaginacionRegistros"
            class="mt-0"
        >
            <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 8px">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs" 
                                icon 
                                color="secondary"
                                @click.stop="visualizarDocumentoConvocatoria(item)"
                            >
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span class="d-inline-block text-center">Ver documento</span>
                    </v-tooltip>


                    <v-tooltip v-if="esAdministradorConvocatorias" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" icon color="secondary" @click.stop="confirmarEliminacion(item)">
                                <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                        </template>
                        <span class="d-inline-block text-center">Eliminar documento</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>
        <ModalCreacionDocumento 
            :isOpen="modalCreacionDocumentoAbierta"
            :id-convocatoria="idConvocatoria"
            @on-visibility-change="cerrarModalCreacionDocumento"
            @on-save="cargarDocumentosConvocatoria"
        />
        <PdfModal
            :isOpen="modalVisualizacionDocumentoAbierta" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="cerrarModalVisualizacionDocumento"
            :filename="`${documentoActivo?.nombre ?? 'documento-proceso'}`"
        />
        <ConfirmationModalComponent 
            :is-open="modalConfirmacionEliminacionAbierto"
            description="¿Desea eliminar el documento?"
            @cancel="modalConfirmacionEliminacionAbierto = false"
            @confirm="eliminarDocumentoConvocatoria"
            :is-loading="eliminacionDocumento.isLoading"
        />
    </v-container>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import ModalCreacionDocumento from './components/documentos/ModalCreacionDocumento.vue';
import ConfirmationModalComponent from '@/components/utils/ConfirmationModalComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { NavButton } from '@/components/utils';
import { formatDate } from '@/utils/datetime';
import { 
    createLoadable, 
    toggleLoadable, 
    setLoadableResponse, 
    createPageable, 
    togglePageable, 
    setPageableResponse, 
    isResponseSuccesful,
} from '@/utils/loadable';

export default {
    name: 'CmDocumentosConvocatoriaView',
    components: { NavButton, DataTableComponent, ModalCreacionDocumento, ConfirmationModalComponent, PdfModal },
    data: () => ({
        idConvocatoria: null,
        tableHeaders: [
            { align: 'center', sortable: false, text: 'Nombre', value: 'nombre' },
            { align: 'center', sortable: false, text: 'Fecha de carga', value: 'created_at' },
            { align: 'center', sortable: false, text: 'Acción', value: 'acciones' },
        ],
        paginaActual: 1,
        registrosPorPagina: 10,
        // Acciones
        documentos: createPageable([], 10),
        eliminacionDocumento: createPageable(null),
        visualizacionDocumento: createLoadable(null),
        documentoActivo: null,
        // UI
        modalCreacionDocumentoAbierta: false,
        modalConfirmacionEliminacionAbierto: false,
        modalVisualizacionDocumentoAbierta: false,
    }),
    computed: {
        total() {
            return this.documentos.pagination.total_rows;
        },
        esAdministradorConvocatorias() {
            return this.haveRoles(['ROLE_CM_ADMINISTRACION_CONVOCATORIAS_VIEW']);
        },
    },
    methods: {
        // Data
        async cargarDocumentosConvocatoria() {
            const filtros = {
                pagination: true,
                per_page: this.registrosPorPagina,
                page: this.paginaActual,
            };

            togglePageable(this.documentos);
            const { data, headers } = await this.services.ConvocatoriaServices.cargarDocumentosConvocatoria(this.idConvocatoria, filtros);
            setPageableResponse(this.documentos, data, headers);
        },
        async eliminarDocumentoConvocatoria() {
            toggleLoadable(this.eliminacionDocumento);
            const { data } = await this.services.ConvocatoriaServices.eliminarDocumentoConvocatoria(this.documentoActivo.id);
            this.modalConfirmacionEliminacionAbierto = false;
            setLoadableResponse(this.eliminacionDocumento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarDocumentosConvocatoria();
        },
        async visualizarDocumentoConvocatoria(documento) {
            this.documentoActivo = documento;
            this.modalVisualizacionDocumentoAbierta = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.ConvocatoriaServices.visualizarDocumentoConvocatoria(documento.id);
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        // UI
        formatDate,
        manejarPaginacionRegistros(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginaActual = pagina;
            this.registrosPorPagina = cantidad_por_pagina;
            this.cargarDocumentosConvocatoria();
        },
        cerrarModalCreacionDocumento(visible) {
            this.modalCreacionDocumentoAbierta = visible;
        },
        confirmarEliminacion(documento) {
            this.documentoActivo = documento;
            this.modalConfirmacionEliminacionAbierto = true;
        },
        cerrarModalVisualizacionDocumento(visible) {
            this.modalVisualizacionDocumentoAbierta = visible;
        },
    },
    created() {
        this.idConvocatoria = this.$route.params.id_convocatoria;
        this.cargarDocumentosConvocatoria();
    },
}
</script>