<template>
    <Fragment>
        <v-dialog v-model="internalOpen" max-width="700" persistent>
            <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
                <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-1">
                    <v-form ref="form" @submit.prevent="manejarEnvioFormulario">
                        <v-text-field  
                            v-model="form.nombre"
                            label="Nombre"
                            placeholder="Nombre"
                            outlined
                            :error-messages="nombreErrors"
                            @change="$v.form.nombre.$touch()"
                            @blur="$v.form.nombre.$touch()"
                        />
                        <v-file-input
                            v-model="form.adjunto"
                            label="Adjunto"
                            placeholder="Seleccione un PDF"
                            outlined
                            light
                            accept="application/pdf"
                            :error-messages="adjuntoErrors"
                            @change="$v.form.adjunto.$touch()"
                            @blur="$v.form.adjunto.$touch()"
                        />
                        <div class="d-flex align-center flex-wrap flex-sm-nowrap mt-4" style="gap: 16px;">
                            <v-btn 
                                class="flex-shrink-1"
                                style="width: 100%"
                                color="secondary"
                                @click.stop="cerrarModal"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn 
                                class="flex-shrink-1" 
                                style="width: 100%"
                                color="primary"
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </div>
        </v-dialog>
        <ConfirmationModalComponent 
            :is-open="modalConfirmacionAbierto"
            description="¿Desea guardar el documento?"
            @cancel="cancelarConfirmacion"
            @confirm="crearDocumento"
            :is-loading="guardadoDocumento.isLoading"
        />
    </Fragment>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { required, maxLength } from 'vuelidate/lib/validators';
import { convertToFormData } from '@/utils/data';
import { Validator } from '@/utils/form-validation.js';
import { Fragment } from 'vue-fragment';
import { isNil } from 'lodash';

export default {
    name: 'ModalCreacionDocumento',
    components: { Fragment, ConfirmationModalComponent },
    emits: ['on-visibility-change', 'on-save'],
    validations () {
        return {
            form: {
                nombre: { required, maxLength: maxLength(100) },
                adjunto: { required, }
            },
        };
    },
    props: {
        isOpen: { type: Boolean },
        idConvocatoria: { type: String },
    },
    data: () =>({
        internalOpen: false,
        modalConfirmacionAbierto: false,
        guardadoDocumento: createLoadable(null),
        form: {
            nombre: null,
            adjunto: null,
        },
    }),
    computed: {
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
    },
    methods: {
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
            this.$v.form.adjunto.$reset();
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarFormulario();
        },
        manejarEnvioFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.internalOpen = false;
            this.modalConfirmacionAbierto = true;
        },
        cancelarConfirmacion() {
            this.limpiarFormulario();
            this.modalConfirmacionAbierto = false;
        },
        async crearDocumento() {
            const formData = convertToFormData({ ...this.form });
            toggleLoadable(this.guardadoDocumento);
            const { data } = await this.services.ConvocatoriaServices.guardarDocumentoConvocatoria(this.idConvocatoria, formData);
            this.modalConfirmacionAbierto = false;
            setLoadableResponse(this.guardadoDocumento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.limpiarFormulario();
                this.$emit('on-save');
            }
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;
            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>